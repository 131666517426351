<template>
  <vue-scroll :ops="{scrollPanel: {easing: 'easeInOutCubic'}}">



  <v-container fluid ref="module-container">

    <v-layout color="transparent" class="elevation-0 mx-3 my-2 align-center">
      <div style="width: 26%;max-width: 26%" class="d-flex flex-nowrap flex-row align-center justify-center">
        <span class="text-no-wrap py-2 px-4">{{ $t("actions.sort") }} :</span>

        <v-select
            v-model="moduleSort"
            :items="sorts"
            return-object
            solo
            dense
            hide-details
        >
          <template #selection="{item}">
            {{ $t(item.title) }}
          </template>
          <template #item="{item}">
              {{ $t(item.title) }}
          </template>
        </v-select>
      </div>
      <v-spacer></v-spacer>
      <v-text-field dense prepend-inner-icon="mdi-magnify" solo hide-details class="search-bar mr-4"
                    clearable @click:clear="searchNeedle = ''"
                    v-model="searchNeedle">
      </v-text-field>
      <v-btn-toggle mandatory borderless v-model="modulesView" class="elevation-2" color="primary">
        <v-btn>
          <v-icon :class="{'switch-selected': modulesView == 0}">mdi-view-grid</v-icon>
        </v-btn>
        <v-btn>
          <v-icon :class="{'switch-selected': modulesView == 1}">mdi-view-module</v-icon>
        </v-btn>
        <v-btn>
          <v-icon :class="{'switch-selected': modulesView == 2}">mdi-apps</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-layout>


    <v-container fluid class="module-grid" :class="{'small': modulesView == 1, 'x-small': modulesView == 2}">

      <template v-if="allModules !== null && allModules.length == 0">
        <v-container fluid
                     v-for="i in 10" :key="i"
                     class="skeleton-loader"
        >
          <v-skeleton-loader type="card" height="175">
          </v-skeleton-loader>
        </v-container>
      </template>
      <template v-else-if="allModules !== null">

        <module v-for="(item,i) in allModules" :key="i" :module="item" class="" :size="modulesView"></module>

      </template>

    </v-container>

  </v-container>
    <template v-if="allModules === null">
      <div class="d-flex flex-column align-center justify-center">Aucun module trouvé</div>
    </template>
  </vue-scroll>
</template>

<script>


import Vuex from "vuex"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "Modules",
  components: {
    Module: () => import("@/Components/Views/Modules/Elements/Module")
  },
  computed: {
    ...Vuex.mapState(["modules", "preferences"]),
    sortedModules() {
      let modules = JSON.parse(JSON.stringify(this.modules.filter(a => a.subModules.filter(b => b.path != "").length > 0)))

      if (!!this.moduleSort && this.moduleSort.value == "alpha-ascend") {
        modules = modules.sort((a,b) => a.name.localeCompare(b.name))

        for (let i = 0; i < modules.length; i++) { // Je parcours tous les modules préfiltrés
          let module = modules[i]
          if (module.subModules.length > 0) {
            module.subModules.sort((a,b) => a.name.localeCompare(b.name))
          }
        }

      }
      if (!!this.moduleSort && this.moduleSort.value == "alpha-descend") {
        modules = modules.sort((a,b) => -a.name.localeCompare(b.name))

        for (let i = 0; i < modules.length; i++) { // Je parcours tous les modules préfiltrés
          let module = modules[i]
          if (module.subModules.length > 0) {
            module.subModules.sort((a,b) => -a.name.localeCompare(b.name))
          }
        }
      }

      return modules
    },
    allModules() {
      let modules = JSON.parse(JSON.stringify(this.sortedModules))
      let searchNeedle = this.escapeRegex(this.searchNeedle)
      let returnModules = []

      if (searchNeedle) { // Si j'ai une recherche effectuée
        searchNeedle = searchNeedle.toLowerCase()
        for (let i = 0; i < modules.length; i++) { // Je parcours tous les modules préfiltrés
          let module = modules[i]
          let returnSubmodules = []

          let match = module.name.match(this.searchNoAccents(searchNeedle)) // Je check si ça match (fonction génialissime de https://itqna.net/questions/514/how-do-search-ignoring-accent-javascript)
          if (match) {  // Si le module match, je modifie la string du nom pour ajouter la surbrillance des résultats pour l'UX
            match = match.map(a => a.toLowerCase())
            match = match.filter((item, pos) => match.indexOf(item.toLowerCase()) == pos)
            for (let m of match) {
              module.name = module.name.replace(new RegExp("("+this.escapeRegex(m)+")", "gi"), "[$1]");
            }
            module.name = module.name.replace(new RegExp(/\[/, "g"), "<span class='foundOccurence'>")
            module.name = module.name.replace(new RegExp(/\]/, "g"), "</span>")
          }

          if (module.subModules.length > 0) {
            for (let j = 0; j < module.subModules.length; j++) { // Pour chaque sous-module
              let submodule = module.subModules[j]
              let subModuleMatch = submodule.name.match(this.searchNoAccents(searchNeedle)) // Je vérifie si le nom match
              if (subModuleMatch) {
                subModuleMatch = subModuleMatch.map(a=>a.toLowerCase())
                subModuleMatch = subModuleMatch.filter((item, pos) => subModuleMatch.indexOf(item) == pos)
                for (let m of subModuleMatch) {
                  submodule.name = submodule.name.replace(new RegExp("("+this.escapeRegex(m)+")", "gi"), "[$1]");
                }

                submodule.name = submodule.name.replace(new RegExp(/\[/, "g"), "<span class='foundOccurence'>")
                submodule.name = submodule.name.replace(new RegExp(/\]/, "g"), "</span>")

                returnSubmodules.push(submodule)
              } else {
                if (match) returnSubmodules.push(submodule)
              }
            }
            if (returnSubmodules.length > 0) {
              module.subModules = returnSubmodules
            }
          }

          if (returnSubmodules.length > 0 || match) { // Si le nom du module match ou qu'au moins un de ses sous-modules match
            returnModules.push(module) // Je le rajoute aux modules affichés
          }
        }

        return returnModules.length > 0 ? returnModules : null
      } else {
        return modules
      }
    },
    modulesView: {
      get() {
        return this.preferences.modulesView
      },
      set(val) {
        this.$store.commit("switchModulesView", {val: val})
        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService.postInterface(store)
      }
    },
    moduleSort: {
      get() {
        return this.preferences.moduleSort
      },
      set(val) {
        this.$store.commit("sortModules", {val: val})
        let store = this.$_.pick(this.$store.state, ["preferences"])
        PlaneteOnlineService.postInterface(store)
      }
    },
  },
  data() {
    return {
      selectedSort: null,
      user: null,
      loading: false,
      searchNeedle: "",
      sorts: [
        {
          title: "app.modules.sorts.initial",
          value: "initial"
        },
        {
          title: "app.modules.sorts.asc",
          value: "alpha-ascend"
        },
        {
          title: "app.modules.sorts.desc",
          value: "alpha-descend"
        }
      ]
    }
  },
}
</script>

<style lang="scss">

.module-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;
}

.module-grid.small {
  grid-template-columns: repeat(8, 1fr);
}

.module-grid.x-small {
  grid-template-columns: repeat(12, 1fr);
}

@media (max-width: 599px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 960px) and (max-width: 1263px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 1264px) and (max-width: 1903px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(9, 1fr);
    grid-gap: 1em;
  }
}

@media (min-width: 1904px) {
  .module-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1em;
  }

  .module-grid.small {
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1em;
  }

  .module-grid.x-small {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1em;
  }
}

.skeleton-loader {
  min-width: 15vh;
  max-width: 45vh;
}

.search-bar {
  max-width: 25%;
}

.foundOccurence {
  border-radius: 2px;
  background: rgba(123,78,142,.3);
}

.switch-selected {
  color: var(--v-primary-base) !important;
}
</style>

